/** @jsx jsx */

import { jsx } from "theme-ui"
import { css } from "@emotion/core"
import { Link } from "gatsby"

function NavLink({ target, children }) {
  return (
    <Link
      to={target}
      sx={{
        color: "accent",
        marginRight: 4,
      }}
      css={css`
        float: right;
        text-decoration: none;
      `}
    >
      {children}
    </Link>
  )
}

export default NavLink
