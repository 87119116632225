/** @jsx jsx */
import { jsx } from "theme-ui"
import NavLink from "./nav-link"
import LogoLink from "./logo-link"
import "typeface-maven-pro"

export default function Layout({ children }) {
  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <header
        sx={{
          width: "100%",
          p: 3,
          mb: 3,
          bg: 'background',
          color: 'primary',
          borderBottom: theme => `1px solid ${theme.colors.gray4}`,
          fontFamily: "heading",
        }}
      >
        <LogoLink />

        <NavLink target="/page/new-work/">New Work</NavLink>
        <NavLink target="/page/resources/">Resources</NavLink>
        <NavLink target="/gallery/">Gallery</NavLink>
        <NavLink target="/page/emergent-forms-film/">Emergent Forms Film</NavLink>
        <NavLink target="/page/about/">About</NavLink>
      </header>
      <main
        sx={{
          flex: "1 1 auto",
          mx: 'auto',
          mb: 6,
          maxWidth: '80vw', // Must match width of SVGContainer
        }}
      >
        {children}
      </main>
      <footer
        sx={{
          backgroundColor: "gray4",
          height: 80,
        }}
      >
        <a
          href="/admin/"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            color: "gray4"
          }}
        >
          Admin
        </a>
      </footer>
    </div>
  )
}
