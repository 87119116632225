/** @jsx jsx */
import { jsx } from "theme-ui"

export const headerStyles = {
  fontFamily: "heading",
  fontWeight: "500",
  color: "primary",
}

export default function Header({ children }) {
  return <h1 sx={headerStyles}>{children}</h1>
}
