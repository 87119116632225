/** @jsx jsx */

import Header, { headerStyles } from "../components/header"

import Layout from "../components/layout"
import React from "react"
import { css } from "@emotion/core"
import { graphql } from "gatsby"
import { jsx } from "theme-ui"

export default function BlogPost({ data }) {
  return (
    <Layout>
      <div
        css={css`
          max-width: 1000px;
        `}
      >
        <PageContent page={data.markdownRemark} />
      </div>
    </Layout>
  )
}

/**
 * Renders an artist page
 */
export function PageContent({ page }) {
  return (
    <>
      <Header>{page.frontmatter.title}</Header>
      {page.frontmatter.vimeo && (
        <iframe
          sx={{
            background: "black",
          }}
          title="vimeo-player"
          src={`https://player.vimeo.com/video/${page.frontmatter.vimeo}`}
          width="100%"
          height="360"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      )}
      {page.frontmatter.youtube && (
        <iframe
          sx={{
            background: "black",
          }}
          title="youtube-player"
          src={`https://www.youtube.com/embed/${page.frontmatter.youtube}`}
          width="100%"
          height="360"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      )}
      <div
        sx={{
          h1: headerStyles,
          h2: headerStyles,
          h3: headerStyles,
          h4: headerStyles,
        }}
        dangerouslySetInnerHTML={{ __html: page.html }}
      />
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        vimeo
        youtube
      }
    }
  }
`
